.oikeuspaaotsikko {
    margin: 2rem 0 2rem 3rem;
    font-size: 2em;
    font-family: 'Russo One';
}

.tietot {
    display: flex;
    justify-content: center;
}

.oikeuslaatikko {
    color: black;
    background-color: white;
    width: 65%;
    padding: 1rem;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    margin-top: 1.2rem;
    font-family: 'poppins';
}

.oikeusotsikko {
    padding-bottom: 0.6rem;
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: 600;
}

.oikeusteksti {
    padding-bottom: 2rem;
}