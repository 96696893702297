.Eulalaatikko {
    color: black;
    background-color: white;
    width: 65%;
    padding: 1rem;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    margin-top: 1.2rem;
    font-family: 'poppins';
}

.EulaSivu {
    display: flex;
    justify-content: center;
    
}

.eulaotsikko {
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: 600;
    padding-bottom: 0.6rem;
}

.eulateksti {
    padding-bottom: 2rem;
}