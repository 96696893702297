.HeadlineTieto {
    margin: 2rem 0 2rem 3rem;
}

.keskita {
    display: flex;
    justify-content: center;
}

.seloste {
    color: black;
    background-color: white;
    width: 65%;
    padding: 1rem;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
}

.osio {
    padding-bottom: 2rem;
}

.osioHeadline {
    padding-bottom: 0.6rem;
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: 600;
}