.Tuote1{
    background-color:#F8F9FB;
    height: 100%;
    width: 100%;
    position: relative;
}
.tuotecontainer{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    background-color: #F8F9FB;
}
.tuotekuvasäilö{
    border: 1px solid rgba(0, 0, 0, 0.8);
    width: 35%;
    align-items: center;
    align-self: center;
    margin: 20px;
}
.tuoteesittely{
    width: 40%;
    height: 50%;
    margin: 20px;
}
.kuvatuote123{
    width: 100%;
    height: 100%;
    align-self: center;
}
.tuote1teksti{
    color: black;
    font-size: 30px;
}
.tuote1otsikko{
    color: black;
    font-size: 40px;
}
.saatavuuscontainer{
    border: 1px solid rgba(0, 0, 0, 0.8);
    height: 100px;
    width: 400px;
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.saatavuustilanne{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: inline-block;
}
.saatavuustextcont{
    height: 60px;
    width: 200px;
}
.saatavuustext{
    font-size: 60px;
    color: black;
}
.hintasaatavuus{
    height: 300px;
    width: 500px;
}
.hintatext{
    font-size: 60px;
    color: black;
    margin-top: 30px;
}
@media only screen and (max-width: 1200px) {
    .tuotecontainer {
        flex-direction: column;
        align-items: center;
    }

    .tuotekuvasäilö,
    .tuoteesittely {
        width: 70%;
    }

    .saatavuuscontainer {
        width: 80%;
    }
    .tuote1otsikko {
        font-size: 23px;
    }
    .tuote1teksti {
        font-size: 20px;
    }
    .hintasaatavuus {
        width: 300px;
    }
    .hintatext {
        font-size: 30px;
    }
    .saatavuustext {
        font-size: 30px;
    }
    .Tuote1 {
        height: auto;
    }
    .saatavuustextcont {
        width: 150px;
    }
}